import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import Relation from '../images/services/rationalizaion.jpg'
import Network from '../images/services/networking.jpg'
import Support from '../images/services/support.jpg'
import Security from '../images/services/security.jpg'
import Management from '../images/services/management.jpg'
import Performance from '../images/services/performance.jpg'
import Backup from '../images/services/backup.jpg'
import Virtualization from '../images/services/virtualization.jpg' 
import Consulting from '../images/services/consulting.jpg'
import Cloud from '../images/services/cloud.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'
import Consulting1 from '../images/services/consulting.jpg'
import Implementation from '../images/services/implementation.jpeg'
import Applications from '../images/services/applications.jpg'
import { StaticImage } from "gatsby-plugin-image"

const SolutionsPage = () => {
    return (
        <Layout pageTitle="Features">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                                <h2><span> Features</span></h2>
                                <div className="horizonral-subtitle"><span>Services</span></div>
                            </div>
                            <a href="#sec1" className="custom-scroll-link hero-start-link"><span>Let's Start</span> <i className="fal fa-long-arrow-down"></i></a>
                        </div>
                    </section>
                   
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >Features <span>//</span></div>
            <div className="container">
                <div className="section-title fl-wrap">
                    <h3>Features</h3>
                    <h2>Pulse <span> Features</span></h2>
                    
                </div>
                <div className="custom-inner-holder  mar-bottom">
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-support-1-icon"></i>
                                    <h3>LIVE DASHBOARD</h3>
                                </div>
                              
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                   
                                    <p>Live dashboard for tracking key parameters for effective status tracking and decision making. The dashboard can be customized to meet your exact needs as part of the implementation and configuration process.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header">
                                    <i className="fal pragicts-2-icon"></i>
                                    <h3>QUESTION BANK</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                   
                                    <p>Define your own questions. Maintain it independently to the survey types and map it as per need to a specific survey. Eliminates the need to define questions for each survey type.</p>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-12-icon"></i>
                                    <h3>ANSWER BANK</h3>
                                </div>
                                
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                   
                                    <p>Define your own answers. Maintain it independently to the questions and map it as per question and as per survey. Eliminates the need to define answers for each question. Also assign a rating for each answer. This enables to compute the rating per survey for effective monitoring and corrective action.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-7-icon"></i>
                                    <h3>MULTIPLE SURVEY TYPES</h3>
                                </div>
                                
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                   
                                    <p>Define multiple survey types as per your organization and stake holder segment needs.</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-6-icon"></i>
                                    <h3>CUSTOMER DETAILS</h3>
                                </div>
                                
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                
                                    <p>Define and maintain customer profile information for effective analysis of surveys.</p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                   
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-9-icon"></i>
                                    <h3>TRANSACTION INSTANCES</h3>
                                </div>
                             
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                
<p>Define and maintain your transaction instances to map and execute surveys for effective survey analysis beyond just customer mappings.</p>                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-3-icon"></i>
                                    <h3>DATA EXTRACTION</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                
<p>Required data can be extracted to your specific needs and further use and analysis</p>                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-10-icon"></i>
                                    <h3>USER ROLES</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                
<p>Access to the Pulse platform is managed through user roles.</p>                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-5-icon"></i>
                                    <h3>INTRO AND COMPLETION MESSAGE</h3>
                                </div>
                               
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                
<p>Define and maintain your pre survey introduction message and post survey message for personalization with customers</p>                                </div>
                            </div>
                            
                        </div>
                    </div>
                   
                 
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal  pragicts-support-2-icon"></i>
                                    <h3>REPORTS</h3>
                                </div>
                              
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                
<p>Customized reports to meet your specific needs. These reports can be viewed online and also exported to csv format for effective analysis and query using Microsoft Excel, etc.</p>                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="custom-inner">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="custom-inner-header ">
                                    <i className="fal pragicts-4-icon"></i>
                                    <h3>3RD PARTY APPLICATION INTEGRATION</h3>
                                </div>
                                
                            </div>
                            <div className="col-md-8">
                                <div className="custom-inner-content fl-wrap">
                                
<p>Pulse architecture enables the integration with any 3rd party application..</p>                                </div>
                            </div>
                            
                        </div>
                    </div>
                    


                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default SolutionsPage
